<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <b-tabs pills justified v-if="tabView">
        <b-tab active class="border-0">
          <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                            <i class="fas fa-home"></i>
                        </span>
            <span class="d-none d-sm-inline-block">Recherche par infos</span>
          </template>
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-8">
                    <form action="">
                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <div class="input-group position-relative">
                            <label for="title" class="col-form-label col-lg-4">Numéro de carte</label>
                            <div class="col-lg-12">
                              <input type="text" class="form-control" v-model="data.cni_numero"
                                     placeholder="">
                            </div>
                            <input type="file" class="form-control d-none" ref="fileInput"
                                   @change="handleFileChange">
                            <button type="button" style="top: 18px; z-index: 1000;"
                                    class="btn btn-success rounded-circle position-absolute top-5 end-0 translate-middle"
                                    @click="openFileInput">
                              <i class="mdi mdi-image"></i>
                            </button>
                          </div>
                        </div>

                        <div class="form-group col-lg-6 ">
                          <label for="nationalite" class="col-form-label col-lg-12">Nationalité</label>
                          <div class="col-lg-12">
                            <input v-model="data.nationalite" id="nationalite" name="nationalite" type="text"
                                   class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="title" class="col-form-label col-lg-12">Nom de la personne</label>
                          <div class="col-lg-12">
                            <input v-model="data.nom" id="nom" name="nom" type="text" class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <label for="title" class="col-form-label col-lg-12">Prenom de la personne</label>
                          <div class="col-lg-12">
                            <input v-model="data.prenom" id="prenom" name="prenom" type="text"
                                   class="form-control" placeholder=""/>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="alias" class="col-form-label col-lg-12">Alias</label>
                          <div class="col-lg-12">
                            <input v-model="data.alias" id="alias" name="alias" type="text" class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="title" class="col-form-label col-lg-12">Date de naissance</label>
                          <div class="col-lg-12">
                            <input v-model="data.date" placeholder="" type="date"
                                   class="form-control">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="age_inf" class="col-form-label col-lg-12">Interval age </label>
                          <div class="col-lg-12">

                            <input v-model="data.age_inf" id="age_inf" name="age_inf" type="number"
                                   class="form-control"/>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <label for="age_sup" class="col-form-label col-lg-12">.</label>
                          <div class="col-lg-12">
                            <input v-model="data.age_sup" id="age_sup" name="age_sup" type="number"
                                   class="form-control" placeholder=""/>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="sexe" class="col-form-label col-lg-12">Sexe </label>
                          <div class="col-lg-12">
                            <multiselect class="form-control" id="sexe" v-model="data.sexe" :options="sexe">
                            </multiselect>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="taille_inf" class="col-form-label col-lg-12">Taille(m) inf</label>
                          <div class="col-lg-12">
                            <input v-model="data.taille_inf" id="taille_inf" name="taille_inf" type="text"
                                   class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                        <div class="form-group col-lg-6 ">
                          <label for="taille_sup" class="col-form-label col-lg-12">Taille(m) Sup</label>
                          <div class="col-lg-12">
                            <input v-model="data.taille_sup" id="taille_sup" name="taille_sup" type="text"
                                   class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="poids" class="col-form-label col-lg-12">Poids</label>
                          <div class="col-lg-12">
                            <input v-model="data.poids" id="poids" name="poids" type="text"
                                   class="form-control" placeholder=""/>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="teint" class="col-form-label col-lg-12">Teint</label>
                          <div class="col-lg-12">
                            <input v-model="data.teint" id="teint" name="teint" type="text" class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <label for="signe" class="col-form-label col-lg-12">Signes particuliers</label>
                          <div class="col-lg-12">
                            <input v-model="data.signe" id="signe" name="signe" type="text"
                                   class="form-control" placeholder=""/>
                          </div>
                        </div>
                      </div>


                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="motif" class="col-form-label col-lg-12">Motif de recherche</label>
                          <div class="col-lg-12">
                            <input v-model="data.motif" id="motif" name="motif" type="text" class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <label for="niveau_dangerosite" class="col-form-label col-lg-12">Niveau de dangérosité</label>
                          <div class="col-lg-12">
                            <multiselect class="form-control" v-model="data.niveau_dangerosite"
                                         :options="niveau"></multiselect>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6 ">
                          <label for="conduite" class="col-form-label col-lg-12">Conduite à tenir</label>
                          <div class="col-lg-12">
                            <input v-model="data.conduite" id="conduite" name="conduite" type="text"
                                   class="form-control"
                                   placeholder=""/>
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-end">
                        <div class="col-md-10">
                          <button @click="searchPersonne()" type="button" class="btn btn-success"
                                  :disabled="spanView" style="float: right">
                            <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                            Rechercher
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                            <i class="far fa-user"></i>
                        </span>
            <span class="d-none d-sm-inline-block">Reconaissance faciale</span>
          </template>
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <form action="">
                  <div class="form-group row mb-4">
                    <div class="col-lg-12">
                      <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="1" :meta="true"
                                    :multiple="true" :accept="'image/*'" :uploadUrl="uploadUrl"
                                    v-model="image"/>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-10">
                      <button @click="searchPersonneImg()" type="button" class="btn btn-success"
                              :disabled="spanView" style="float: right">
                        <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                        Rechercher
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                            <i class="far fa-user"></i>
                        </span>
            <span class="d-none d-sm-inline-block">Reconaissance d'empreinte digitale</span>
          </template>
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h6>Lecture d'empreinte en cours</h6>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div class="row" v-if="tableView">
        <div class="card">
          <div class="card-title">
            <a href="#" @click="tableView = false; tabView=true">Retourner sur le formulaire de recherche</a>
          </div>
          <div class="card-body">

            <div class="table-responsive mt-3">
              <div v-if="people.length > 0">
                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12" v-for="personne in people" :key="personne.id">
                    <a @click="detailsPerson(personne)" style="text-decoration: none; color: inherit;">
                      <div class="card mb-4 text-center">
                        <div class="col-sm-12">
                          <div class="d-flex justify-content-center align-items-center" style="height: 150px; overflow: hidden;">
                            <img v-if="personne?.photos?.length > 0" class="d-block img-fixed-size" style="max-width: 100%; max-height: 100%;" :src="`https://api.alcit.sims-technologie.com${personne?.photos[0]?.photos}`" alt="">
                            <img v-else class="d-block img-fixed-size" style="max-width: 100%; max-height: 100%;" src="https://static.thenounproject.com/png/363640-200.png" alt="">
                          </div>
                        </div>
                        <div class="card-body">
                          <h5 class="card-title text-info">{{ personne.nom }} <br> {{ personne.prenom }}</h5>
                          <p class="card-text"><strong>Sexe: </strong>{{ personne.sexe }}, <br/> <strong>Age: </strong></p>
                          <p class="card-text"><strong>Motif: </strong>{{ personne.motif_recherche }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div v-else class="text-center mt-3">
                <h5>Aucune Personne Trouvée</h5>
              </div>
            </div>



          </div>
        </div>
      </div>
<!--      <div class="row" v-if="tableView">
        <div class="card">
          <div class="card-title">
            <a href="#" @click="tableView = false; tabView=true">Retourner sur le formulaire de recherche</a>
          </div>
          <div class="card-body">
            <div class="table-responsive mt-3">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" v-for="personne in people" :key="personne.id">
                  <div class="card float-right" @click="detailsPerson(personne)">
                    <div class="row">
                      <div class="col-sm-5">
                        <img v-if="personne?.photos?.length > 0" class="d-block img-fixed-size" width="100" height="100" :src="`https://api.alcit.sims-technologie.com${personne?.photos[0]?.photos}`" alt="">
                        <img v-else class="d-block img-fixed-size" width="100" height="100" src="https://static.thenounproject.com/png/363640-200.png" alt="">
                      </div>
                      <div class="col-sm-7">
                        <div class="card-block">
                          &lt;!&ndash;           <h4 class="card-title">Small card</h4> &ndash;&gt;
                          <strong>{{ personne.nom }} {{ personne.prenom }}</strong>
                          <p><strong>Sexe: </strong> {{ personne.sexe }}, <br/> <strong>Age: </strong> </p>
                          <p><strong>Status: </strong> {{ personne.status }} </p>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <p><strong>Motif: </strong> {{ personne.motif_recherche }}</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="Object.keys(people).length == 0" class="text-center mt-3">
              <h5>Aucune Peronne Trouvée</h5>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {VueFileAgent} from '@boindil/vue-file-agent-next';
import Multiselect from "@vueform/multiselect";
import axios from 'axios';

export default {
  components: {
    Layout,
    PageHeader,
    VueFileAgent,
    Multiselect
  },
  data() {
    return {
      spanView: false,
      tabView: true,
      tableView: false,
      cardPhoto: null,
      authError: null,
      people: [],
      title: "Rechercher Personne",
      niveau: [{value: 'normal', label: 'Normal'},
        {value: 'dangereux', label: 'Dangereux'},
        {value: 'tres_dangereux', label: 'Très dangereux'},],
      items: [
        {
          text: "Recherche",
          href: "/",
        },
        {
          text: "Personne recherchée",
          active: true,
        },
      ],
      data: {
        cni_numero: "",
        nom: "",
        prenom: "",
        sexe: "",
        date: "",
        age_inf: "",
        nationalite: "",
        alias: "",
        poids: "",
        teint: "",
        signe: "",
        motif: "",
        niveau_dangerosite: "",
        conduite: "",
        age_sup: "",
        taille_inf: "",
        taille_sup: "",
      },
      image: [],
      sexe: [
        {value: 'feminin', label: 'Feminin'},
        {value: 'masculin', label: 'Masculin'},
      ],
    }
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.cardPhoto = event.target.files[0];
      this.data.cni_numero = this.cardPhoto ? this.cardPhoto.name : '';
    },
    async searchPersonne() {
      this.spanView = true;
      const that = this;
      const formData = new FormData();
      formData.append('cni_numero', this.data.cni_numero);
      formData.append('nom', this.data.nom);
      formData.append('prenom', this.data.prenom);
      formData.append('sexe', this.data.sexe);
      formData.append('date', this.data.date);
      formData.append('cni_image', this.cardPhoto);
      formData.append('age_inf', this.data.age_inf);
      formData.append('nationalite', this.data.nationalite);
      formData.append('alias', this.data.alias);
      formData.append('poids', this.data.poids);
      formData.append('teint', this.data.teint);
      formData.append('signe', this.data.signe);
      formData.append('motif', this.data.motif);
      formData.append('niveau_dangerosite', this.data.niveau_dangerosite);
      formData.append('conduite', this.data.conduite);
      formData.append('age_sup', this.data.age_sup);
      formData.append('taille_inf', this.data.taille_inf);
      formData.append('taille_sup', this.data.taille_sup);
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/personne/search/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        that.people = response.data;
        that.spanView = false;
        that.tabView = false;
        that.tableView = true;
      } catch (error) {
        that.spanView = false;
        that.authError = error;
      }
    },
    async searchPersonneImg() {
      console.log(this.image);
      this.spanView = true;
      const that = this;
      const formData = new FormData();
      for (const img of this.image) {
        formData.append('image', img.file);
      }
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/personne/facialsearch/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        that.people = response.data;
        that.spanView = false;
        that.tabView = false;
        that.tableView = true;
      } catch (error) {
        that.spanView = false;
        that.authError = error;
      }
    },
    detailsPerson(personne) {
      localStorage.setItem('person', JSON.stringify(personne))
      this.$router.push({path: `/DelailPerson/${personne.id}`})
    },
  }
}

</script>
<style scoped>
.img-fixed-size {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.card-block {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, .1);
  box-shadow: none;
}

.card {
  font-size: 1em;
  overflow: hidden;
  padding: 5px;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
}

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040;
}

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040;
}

.carousel-indicators .active {
  background-color: white;
  max-width: 12px;
  margin: 0 3px;
  height: 12px;
}
.btn {
  margin-top: auto;
}
</style>
